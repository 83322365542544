import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Row as TanTableRow, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import type { FirmsController } from "../../../api/src/firms/firms.controller";
import type { AssetClassRiskAllocation } from "../../../api/src/firms/firms.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import TabContainerWithTabs from "../components/TabContainer";
import EditableTableRow from "../components/Table/EditableTableRow";
import { Table, useTable } from "../components/Table/Table";
import {
  YesNoSingleFilter,
  multiSelectIncludes,
  yesNoOptions,
} from "../components/Table/filters";
import {
  deserializeDate,
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
  useAuthenticatedQuery,
} from "../lib/api";
import { useRoles } from "../lib/auth";
import { processFileResponse } from "../lib/file";
import { formatPercent } from "../lib/numbers";
import FirmNav from "./FirmNav";

function useQueryExportRiskAllocationsAssetClass() {
  return useAuthenticatedQuery<
    UnpackResponse<FirmsController["exportRiskAllocationsForAssetClasses"]>,
    void
  >({
    queryKey: ["firm", "asset-class-risk-allocations", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

type RiskAllocationsRow = AssetClassRiskAllocation;
type RiskAllocationRequest = {
  ratio: number;
  mode: "INSERT" | "UPDATE" | "DELETE";
};

const RiskAllocationsAssetClass = () => {
  const roles = useRoles();
  const [fields, setFields] = useState<Record<string, number>>({});
  const [riskAllocationsEdited, setRiskAllocations] = useState<
    AssetClassRiskAllocation[]
  >([]);
  const [changedRiskAllocations, setChangedRiskAllocations] = useState<
    Record<string, RiskAllocationRequest>
  >({});

  const onUpdateRiskAllocations = useCallback(
    (assetClass: string, value: number) => {
      setRiskAllocations(
        riskAllocationsEdited.map((riskAllocation) => {
          if (riskAllocation.assetClass !== assetClass) {
            return riskAllocation;
          }

          if (value !== riskAllocation.originalRatio) {
            setChangedRiskAllocations((changedRiskAllocations) => ({
              ...changedRiskAllocations,
              [riskAllocation.assetClass]: {
                ratio: value,
                mode:
                  riskAllocation.defaultRatio === value
                    ? "DELETE"
                    : riskAllocation.originalRatio ===
                        riskAllocation.defaultRatio
                      ? "INSERT"
                      : "UPDATE",
              },
            }));
          } else {
            setChangedRiskAllocations((changedRiskAllocations) => {
              delete changedRiskAllocations[riskAllocation.assetClass];
              return { ...changedRiskAllocations };
            });
          }

          return {
            ...riskAllocation,
            growth: value,
            defensive: 100 - value,
            isOverride: riskAllocation.defaultRatio !== value,
          };
        }),
      );
    },
    [riskAllocationsEdited],
  );

  const onChangeText = useCallback(
    (cellId: string, rowId: string, value: string) => {
      const updatedValue = +value;
      setFields({
        ...fields,
        [cellId]: updatedValue,
        [`${rowId}_${cellId.endsWith("growth") ? "defensive" : "growth"}`]:
          100 - updatedValue,
      });
    },
    [fields],
  );

  const onResetRow = useCallback(
    (row: TanTableRow<RiskAllocationsRow>) => {
      onUpdateRiskAllocations(
        row.original.assetClass,
        row.original.defaultRatio,
      );
    },
    [onUpdateRiskAllocations],
  );

  const onConfirmRow = useCallback(
    (row: TanTableRow<RiskAllocationsRow>) => {
      onUpdateRiskAllocations(row.id, fields[`${row.id}_growth`]);
    },
    [onUpdateRiskAllocations, fields],
  );

  const onEditRow = useCallback(
    (row: TanTableRow<RiskAllocationsRow>) => {
      const currentRow = riskAllocationsEdited.find(
        (riskAllocation) =>
          riskAllocation.assetClass === row.original.assetClass,
      );

      if (typeof currentRow === "undefined") {
        throw new Error("Row not found in table: ", { cause: row.id });
      }

      setFields({
        ...fields,
        [`${row.id}_growth`]: currentRow.growth,
        [`${row.id}_defensive`]: currentRow.defensive,
      });
    },
    [riskAllocationsEdited, fields],
  );

  const onDiscardRow = useCallback(
    (row: TanTableRow<RiskAllocationsRow>) => {
      setFields({
        ...fields,
        [`${row.id}_growth`]: row.original.growth,
        [`${row.id}_defensive`]: row.original.defensive,
      });
    },
    [fields],
  );

  const columnHelper = useMemo(
    () => createColumnHelper<RiskAllocationsRow>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("assetClass", {
        header: () => "Asset Class",
      }),
      columnHelper.accessor("growth", {
        cell: (info) => formatPercent(info.getValue() / 100),
        header: () => "Growth",
        enableColumnFilter: false,
        meta: {
          editableComponent: (cell) => (
            <InputGroup style={{ width: "100px" }}>
              <Form.Control
                value={fields[cell.id]}
                onChange={(e) =>
                  onChangeText(cell.id, cell.row.id, e.target.value)
                }
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          ),
        },
      }),
      columnHelper.accessor("defensive", {
        cell: (info) => formatPercent(info.getValue() / 100),
        header: () => "Defensive",
        enableColumnFilter: false,
        meta: {
          editableComponent: (cell) => (
            <InputGroup style={{ width: "100px" }}>
              <Form.Control
                value={fields[cell.id]}
                onChange={(e) =>
                  onChangeText(cell.id, cell.row.id, e.target.value)
                }
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          ),
        },
      }),
      columnHelper.accessor(
        (row) => {
          const override = yesNoOptions.find(
            (a) => a.label === (row.growth !== row.defaultRatio ? "Yes" : "No"),
          );
          return override?.value.toString() ?? "";
        },
        {
          id: "override",
          cell: (info) => {
            const override = yesNoOptions.find(
              (a) => a.value.toString() === info.getValue(),
            );
            return override?.label === "No" ? null : (
              <Badge bg="warning" text="dark">
                Override
              </Badge>
            );
          },
          header: () => "Override",
          filterFn: multiSelectIncludes,
          meta: {
            filterComponent: YesNoSingleFilter,
          },
        },
      ),
      columnHelper.accessor("updatedTime", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Updated Time",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "actions",
        size: 120,
      }),
    ],
    [columnHelper, onChangeText, fields],
  );

  const { isPending: isPendingRiskAllocations, data: dataRiskAllocations } =
    useAuthenticatedFetch<
      SerializedObject<
        UnpackResponse<FirmsController["getAllRiskAllocationsForAssetClasses"]>
      >
    >("/firm/asset-class-risk-allocations");

  const riskAllocations = useMemo(
    () =>
      (dataRiskAllocations?.data ?? []).map((riskAllocation) => ({
        ...riskAllocation,
        updatedTime: deserializeDate(riskAllocation.updatedTime),
      })),
    [dataRiskAllocations?.data],
  );

  useEffect(() => {
    if (
      !isPendingRiskAllocations &&
      typeof dataRiskAllocations?.data !== "undefined"
    ) {
      setRiskAllocations(riskAllocations);
      setFields(
        riskAllocations.reduce(
          (result, riskAllocation) => ({
            ...result,
            [`${riskAllocation.assetClass}_growth`]: riskAllocation.growth,
            [`${riskAllocation.assetClass}_defensive`]:
              riskAllocation.defensive,
          }),
          {},
        ),
      );
    }
  }, [dataRiskAllocations?.data, isPendingRiskAllocations, riskAllocations]);

  const { table } = useTable({
    columns: roles.includes("Admin")
      ? columns
      : columns.filter((column) => column.id !== "isOverride"),
    data: riskAllocationsEdited,
    getRowId: (row) => row.assetClass,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    autoResetAll: false,
  });

  const updateRiskAllocations = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<FirmsController["updateRiskAllocationsForAssetClass"]>
    >
  >(
    "/firm/asset-class-risk-allocations",
    async (data: Record<string, RiskAllocationRequest>) => ({
      method: "PUT",
      body: JSON.stringify(data),
    }),
  );

  const handleDiscardChanges = useCallback(() => {
    setRiskAllocations(
      riskAllocations.map((riskAllocation) => ({
        ...riskAllocation,
        growth: riskAllocation.originalRatio,
        defensive: 100 - riskAllocation.originalRatio,
        isOverride:
          riskAllocation.originalRatio !== riskAllocation.defaultRatio,
      })),
    );
    setChangedRiskAllocations({});
  }, [riskAllocations]);

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async () => {
      try {
        const updatedRiskAllocations = await updateRiskAllocations(
          changedRiskAllocations,
        );
        queryClient.setQueryData(["/firm/asset-class-risk-allocations"], {
          data: updatedRiskAllocations.data,
        });
        setChangedRiskAllocations({});
        notificationContext.pushNotification({
          id: "risk-allocations",
          header: "Risk Allocations Updated",
          body: "Firm risk allocation settings updated",
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save risk allocations details", err);
        notificationContext.pushNotification({
          id: "risk-allocations",
          header: "Failed to Save Risk Allocations",
          body: "Firm risk allocation settings not saved",
          variant: "danger",
        });
      }
    },
  });

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportRiskAllocationsAssetClass();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={FirmNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                disabled={isLoadingExport}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row
        hidden={Object.keys(changedRiskAllocations).length <= 0}
        className="mb-1"
      >
        <Col className="d-grid gap-1" xl={6} md={12}>
          <Button
            className="mb-1"
            variant="success"
            onClick={() => onSubmit.mutateAsync()}
            disabled={onSubmit.isPending}
          >
            Submit{" "}
            {onSubmit.isPending ? <Spinner animation="border" size="sm" /> : ""}
          </Button>
        </Col>
        <Col className="d-grid gap-1" xl={6} md={12}>
          <Button
            className="mb-1"
            variant="danger"
            onClick={handleDiscardChanges}
            disabled={onSubmit.isPending}
          >
            Discard
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ pointerEvents: onSubmit.isPending ? "none" : "auto" }}>
          {isPendingRiskAllocations ? (
            <Loading />
          ) : (
            <Table
              table={table}
              RowComp={roles.includes("Admin") ? EditableTableRow : undefined}
              onSave={onConfirmRow}
              onEdit={onEditRow}
              onDiscard={onDiscardRow}
              editComp={(row) => (
                <ActionButton
                  type="button"
                  onClick={() => onResetRow(row)}
                  disabled={row.original.growth === row.original.defaultRatio}
                  variant="icon"
                  label="Reset"
                  icon="/icons/redo.svg"
                />
              )}
            />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default RiskAllocationsAssetClass;
