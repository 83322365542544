import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Path, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import type {
  RebalanceRequest,
  RebalanceType,
} from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import Content from "../components/Content";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import { RowExpander } from "../components/Table/RowExpander";
import { Table, useTable } from "../components/Table/Table";
import { useAuthenticatedMutationNew } from "../lib/api";
import { displayAccountName } from "../lib/display";
import { formatCurrency } from "../lib/numbers";
import { rebalanceTypeDisplay } from "./lib";

const RebalanceTypeHelp = () => (
  <section className="text-start">
    <h5>Tolerance</h5>
    <p>
      Realign a household portfolio with their model (i.e. target) portfolio.
    </p>
    <h5>Raise Cash</h5>
    <p>Sell holdings to generate cash in an account.</p>
    <h5>Spend Cash</h5>
    <p>Use cash in an account to buy securities.</p>
    <h5>Manual</h5>
    <p>Enter individual trades manually.</p>
  </section>
);

type RebalanceRowFormState = Omit<
  RebalanceRequest,
  "householdId" | "householdName" | "type" | "trades" | "accounts" | "status"
> & {
  id: number;
  name: string;
  type: "household" | "account";
  balance: number;
  cashBalance: number;
  raiseSpendCashAmount?: number;
  accounts?: (RebalanceRowFormState & {
    type: "account";
    parentId: string;
  })[];
};

type RebalanceFormState = {
  households: (RebalanceRowFormState & {
    type: "household";
  })[];
};

function useCreateRebalance() {
  return useAuthenticatedMutationNew<
    SerializedObject<UnpackResponse<RebalancesController["createRebalances"]>>,
    void,
    RebalanceRequest[]
  >({
    mutationKey: ["POST", "rebalances"],
    mutationFn: () => null,
  });
}

const CreateRebalance = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RebalanceFormState>();

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({ includeBalances: true });

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({
    includeBalances: true,
    pageSize: 10000,
  });

  const householdsWithAccounts: RebalanceFormState | undefined = useMemo(() => {
    if (
      typeof dataHouseholds !== "undefined" &&
      typeof dataAccounts !== "undefined"
    ) {
      const accountsMap = _.groupBy(dataAccounts.data, "householdId");
      const households = dataHouseholds.data;

      return {
        households: households
          .filter(
            (household) =>
              typeof household.assignedModelId !== "undefined" ||
              (accountsMap[household.id] ?? []).some(
                (account) => typeof account.assignedModelId !== "undefined",
              ),
          )
          .map((household) => ({
            id: household.id,
            name: household.name,
            type: "household",
            balance: household.householdBalance ?? 0,
            cashBalance: household.cashBalance ?? 0,
            accounts: (accountsMap[household.id] ?? [])
              .filter(
                (account) =>
                  typeof household.assignedModelId !== "undefined" ||
                  typeof account.assignedModelId !== "undefined",
              )
              .map((account) => ({
                id: account.id,
                name: displayAccountName(
                  account.displayName,
                  account.displayNumber,
                ),
                type: "account",
                balance: account.accountBalance ?? 0,
                cashBalance: account.cashBalance ?? 0,
                parentId: `household-${household.id}`,
              })),
          })),
      };
    }
  }, [dataHouseholds, dataAccounts]);

  useEffect(() => {
    if (typeof householdsWithAccounts !== "undefined") {
      reset(householdsWithAccounts);
    }
  }, [reset, householdsWithAccounts]);

  const [rebalanceType, setRebalanceType] = useState<RebalanceType | "">();
  const rebalanceTypeIsSet =
    typeof rebalanceType !== "undefined" && rebalanceType !== "";

  const columnHelper = useMemo(
    () => createColumnHelper<RebalanceRowFormState>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: (info) => (
          <IndeterminateCheckbox
            checked={info.table.getIsAllRowsSelected()}
            indeterminate={info.table.getIsSomeRowsSelected()}
            onChange={info.table.getToggleAllPageRowsSelectedHandler()}
          />
        ),
        cell: (info) =>
          rebalanceType === "tolerance" || rebalanceType === "manual" ? null : (
            <IndeterminateCheckbox
              id={`select-${info.row.original.id}`}
              checked={info.row.getIsSelected()}
              indeterminate={info.row.getIsSomeSelected()}
              onChange={info.row.getToggleSelectedHandler()}
            />
          ),
        aggregatedCell: (info) => (
          <IndeterminateCheckbox
            id={`select-${info.row.original.id}`}
            checked={info.row.getIsSelected()}
            indeterminate={info.row.getIsSomeSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        ),
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${info.row.depth * 2}rem`,
            }}
          >
            <Link
              to={`/clients/${info.row.depth <= 0 ? "households" : "accounts"}/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        aggregatedCell: (info) => (
          <>
            {info.row.getCanExpand() ? <RowExpander row={info.row} /> : ""}{" "}
            <Link
              to={`/clients/${info.row.depth <= 0 ? "households" : "accounts"}/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          </>
        ),
        header: () => "Household",
      }),
      columnHelper.accessor("balance", {
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        aggregatedCell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
      }),
      ...(rebalanceType === "raise_cash" || rebalanceType === "spend_cash"
        ? [
            columnHelper.accessor("raiseSpendCashAmount", {
              cell: (info) =>
                info.row.depth <= 0 ? null : (
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        max={
                          rebalanceType === "spend_cash"
                            ? info.row.original.cashBalance
                            : info.row.original.balance -
                              info.row.original.cashBalance
                        }
                        {...register(
                          `households.${info.row.getParentRow()?.index}.accounts.${info.row.index}.raiseSpendCashAmount` as Path<RebalanceFormState>,
                        )}
                      />
                    </InputGroup>
                    <FormFieldError
                      field={
                        errors.households?.[info.row.getParentRow()!.index]
                          ?.accounts?.[info.row.index]?.raiseSpendCashAmount
                      }
                    />
                  </Form.Group>
                ),
              header: () =>
                rebalanceType === "spend_cash"
                  ? "Cash to Spend"
                  : "Cash to Raise",
              enableColumnFilter: false,
            }),
          ]
        : []),
    ],
    [columnHelper, errors.households, rebalanceType, register],
  );

  const { table, rowSelection } = useTable({
    columns,
    data: householdsWithAccounts?.households ?? [],
    getRowId: (row) => `${row.type}-${row.id}`,
    getSubRows: (row) => row.accounts ?? [],
  });

  const createRebalance = useCreateRebalance();

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: RebalanceFormState) => {
      if (!rebalanceTypeIsSet) {
        console.error("RebalanceType not set");
        return;
      }

      const req = data.households
        .filter((household) =>
          rebalanceType === "tolerance" || rebalanceType === "manual"
            ? rowSelection[`household-${household.id}`]
            : (household.accounts ?? []).some(
                (account) => rowSelection[`account-${account.id}`],
              ),
        )
        .map(
          (household): RebalanceRequest => ({
            ...household,
            householdId: household.id,
            householdName: household.name,
            type: rebalanceType,
            status: rebalanceType === "manual" ? "proposed" : "requested",
            accounts: (household.accounts ?? [])
              .filter((account) => rowSelection[`account-${account.id}`])
              .map((account) => ({
                id: account.id,
                accountName: account.name,
                raiseSpendCashAmount: parseFloat(
                  (account.raiseSpendCashAmount as string | undefined) ?? "0",
                ),
                trades: [],
              })),
          }),
        );

      try {
        await createRebalance.mutateAsync(req);

        notificationContext.pushNotification({
          id: "rebalance",
          header: "Rebalance Created",
          body: `${rebalanceTypeDisplay[rebalanceType]} rebalance created`,
          variant: "success",
        });

        navigate("..");
      } catch (err) {
        console.error("Failed to create contact", err);
        notificationContext.pushNotification({
          id: "rebalance",
          header: "Failed to Create Rebalance",
          body: "Rebalance was not created",
          variant: "danger",
        });
      }
    },
    [
      createRebalance,
      navigate,
      notificationContext,
      rebalanceType,
      rebalanceTypeIsSet,
      rowSelection,
    ],
  );

  const setRebalanceTypeHandler = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) =>
      setRebalanceType(ev.target.value as RebalanceType | ""),
    [],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Create Trade</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-4">
              <ActionButton
                label="Run Rebalance"
                icon="/icons/save.svg"
                type="submit"
                disabled={!rebalanceTypeIsSet}
              />
            </ButtonGroup>
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="/rebalances"
                label="Exit"
                icon="/icons/exit.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col md={3} className="mb-3">
            <Form.Group controlId="formType">
              <Form.Label>Rebalance Type</Form.Label>
              <div className="d-flex">
                <Form.Select
                  placeholder="Select trade type"
                  value={rebalanceType}
                  onChange={setRebalanceTypeHandler}
                  className="me-1"
                >
                  <option value="">Select...</option>
                  {Object.entries(rebalanceTypeDisplay).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
                <HelpTooltip tooltip={<RebalanceTypeHelp />} />
              </div>
            </Form.Group>
          </Col>
        </Row>
        {rebalanceTypeIsSet && (
          <Row>
            <Col>
              {isPendingHouseholds || isPendingAccounts ? (
                <Loading />
              ) : isErrorHouseholds || isErrorAccounts ? (
                <Alert variant="danger">
                  An error occurred loading households and accounts
                </Alert>
              ) : (
                <Table table={table} />
              )}
            </Col>
          </Row>
        )}
      </Content>
    </Form>
  );
};

export default CreateRebalance;
